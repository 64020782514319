.product-toppings-selector-list {
    display: flex;
    justify-content: center;
}

.product-toppings-selector {
    margin: 5px;
}
.product-toppings-selector-name {
    text-align: center;
    color: #fff;
    font-size: 24px;
}
.toppings-lists {
    display: flex;
}
.toppings-lists .toppings-lists-name {
    text-align: center;
    color: #fff;
    font-size: 18px;
    display: block;
}
.toppings-list {
    margin: 5px;
    flex-wrap: wrap;
    display: flex;
    max-width: 250px;
}
.toppings-list .topping {
    flex-basis: 0;
    flex-grow: 1;
    margin: 4px 3px;
}

.toppings-list .topping input {
    display: none;
}

.toppings-list .topping label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
}

.toppings-list .topping input:checked + label {
}

@media (max-width: 800px) {
    .product-toppings-selector-list {
        display: block;
        overflow-y: scroll;
        height: 450px;
    }
    .product-toppings-selector-list::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(255,255,255,0.3);;
    }
    
    .product-toppings-selector-list::-webkit-scrollbar {
        height: 16px;
        background-color: transparent;
    }
    
    .product-toppings-selector-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
    }
}