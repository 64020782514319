.well {
    padding: 10px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39);
}

input[type="text"] {
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.2), 0 0 6px rgba(0,0,0,0.2);
}

#admin {
    height: 100%;
    overflow: auto;
    background-color: #333;
}

#nav {
    padding: 5px 10px 0 10px;
}
#nav::after {
    content: "";
    clear: both;
    display: table;
}
#nav-title {
    float: right;
    color: #fff;
    margin: 0;
}
#nav-buttons {
    float: left;
}

#nav-buttons button i {
    margin-left: 5px;
}

#admin #slots-list {
    list-style: none;
    padding: 0;
}

#admin #slots-list .slot {
    display: flex;
    flex-flow: row wrap;
    margin: 5px;
    border-radius: 5px;
}

#admin #slots-list .slot.odd-hour-slot {
    background-color: rgba(255, 255, 255, 0.05);
}

#admin #slots-list .slot.even-hour-slot {
    background-color: rgba(255, 255, 255, 0.1);
}


#admin #slots-list .slot-time {
    flex-grow: 0;
    color: #fff;
    padding: 10px;
    font-weight: 800;
    opacity: 0.7;
    border-left: #999 dashed 1px;
    display: flex;
    align-items: center;

    display: flex;
    flex-direction: column;
}

#admin #slots-list .slot-time-to {
    opacity: 0.3;
    font-size: 14px;
    font-weight: 400;
}

#admin #slots-list .lock-slot,
#admin #slots-list .add-order {
    flex-grow: 0;
    width: 50px;
    margin: 0 7px;
}

#admin #slots-list .lock-slot.btn3d,
#admin #slots-list .add-order.btn3d {
    position: static;
    margin-top: 10px;
    margin-bottom: 16px;
}

#admin #slots-list .lock-slot.btn3d:active,
#admin #slots-list .add-order.btn3d:active {
    margin-top: 16px;
    margin-bottom: 10px;
}

#admin .subSlots-list-container {
    flex-grow: 1;
}

#admin .subSlots-list {
    list-style: none;
    padding: 0;
    display: flex;
}


#admin .subSlots-list .subSlot {
    flex-grow: 1;
    flex-basis: 0;
    border-left: 3px solid #333;
}

#admin .subSlots-list .subSlot:nth-last-child(2) {
    background-image: linear-gradient(45deg, rgba(0,0,0,0.05) 21.43%, transparent 21.43%, transparent 50%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.05) 71.43%, transparent 71.43%, transparent 100%);
    background-size: 9.90px 9.90px;
}

#admin .subSlots-list .subSlot:last-child {
    flex-grow: 0.5;
    background-image: linear-gradient(45deg, rgba(255, 0, 0, 0.1) 21.43%, transparent 21.43%, transparent 50%, rgba(255,0,0,0.1) 50%, rgba(255,0,0,0.1) 71.43%, transparent 71.43%, transparent 100%);
    background-size: 9.90px 9.90px;
}
#admin .orders-list {
    list-style: none;
    padding: 0;
    display: flex;
}

#admin .orders-list .order {
    text-align: center;
    padding-left: 6px;
}
#admin .orders-list .order:first-child {
    padding-right: 3px;
}


#admin .orders-list .order.unnoted {
    margin-bottom: 3px;
    border-bottom-width: 3px;
    border-bottom-style: solid;

    border-image: linear-gradient(to left, transparent, #ff0000, #ff0000, transparent);
    border-image-slice: 1;
    
    /*border-bottom-color: #ff0000;*/
}

#admin .orders-list .order.unnoted .btn3d {
    margin-bottom: 4px;
}

#admin .orders-list .order a {
    width: 100%;
    padding: 5px;
}

#admin .orders-list .order .order-icon img {
    height: 25px;
}

#admin .orders-list .order .order-clientName {
    font-size: 12px;
    line-height: 18px;
}

#admin .orders-list .order.order-size-4 .order-clientName {
    font-size: 11px;
    line-height: 18px;
}
#admin .orders-list .order.order-size-4 .order-icon img {
    height: 20px;
    margin: 2.5px 0;
}

#admin .order-details-container,
#admin .add-order-container {
    height:0;
    overflow: hidden;
    transition:
        height 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94),
        padding 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    box-sizing: border-box;
}

#admin .order-details-container.show,
#admin .add-order-container.show {
    display: block;
}

#admin .order-details form {
    display: flex;
    margin-right: 10px;
}

#admin .order-details form .order-details-inputs-group label {
    margin-left: 10px;
    color: #fff;
    display: block;
}

#admin .order-details form>label {
    color: #fff;
}

#admin .order-details-inputs-group {
}

#admin .order-details-buttons {
    display: flex;
    flex-direction: column;
}

#admin .order-details-buttons i {
    margin-left: 5px;
}

#charging-window-container,
#change-date-window-container {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0,0,0,0.5);
}

#charging-window-container #charging-window,
#change-date-window-container #change-date-window {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    height: 200px;
    margin-top: -100px;

    background-color: #999;
    box-shadow: 0 0 10px #000;
    border-radius: 4px;
    padding: 40px 10px;

    text-align: center;
}

#charging-window-container #charging-window .form-inline,
#change-date-window-container #change-date-window .form-inline {
    justify-content: center;
}

#charging-window-container #charging-window input,
#charging-window-container #charging-window button,
#change-date-window-container #change-date-window input,
#change-date-window-container #change-date-window button {
    margin-left: 5px;
}

#charging-window-container #charging-window input[name="chargingYear"],
#change-date-window-container #change-date-window input[name="changeDateYear"] {
    width: 70px;
}

#charging-window-container #charging-window input[name="chargingMonth"],
#charging-window-container #charging-window input[name="chargingDay"],
#change-date-window-container #change-date-window input[name="changeDateMonth"],
#change-date-window-container #change-date-window input[name="changeDateDay"] {
    width: 50px;
}