
html, body {
    overflow: hidden;
    scroll-behavior: smooth;
}

html, body, #root, #client, #screens-wraper {
    height: 100%;
}

#screens-wraper {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.screen {
    min-height: 100%;
    position: relative;
    display: flex;
}

.screen-inner {
    margin: auto;
}

.navigation {
    background-color: #333;
    position: absolute;
    width: 100%;
    -webkit-transition: opacity 1s; /* Safari */
    transition: opacity 1s;
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: 800;
}

.navigation:hover {
    text-decoration: none;
    color: #fff;
}
.navigation i {
    display: block;
    
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
.button-back:hover i {
animation-name: bounce-back;
-moz-animation-name: bounce-back;
}
.button-next:hover i {
    animation-name: bounce-next;
    -moz-animation-name: bounce-next;
}

.button-back {
    top: 0;
}
.button-next {
    bottom: 0;
}

@keyframes bounce-back {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(10px);
		-ms-transform:     translateY(10px);
		transform:         translateY(10px)
	}
	60% {
		-webkit-transform: translateY(5px);
		-ms-transform:     translateY(5px);
		transform:         translateY(5px)
	}
}

@keyframes bounce-next {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-10px);
		-ms-transform:     translateY(-10px);
		transform:         translateY(-10px)
	}
	60% {
		-webkit-transform: translateY(-5px);
		-ms-transform:     translateY(-5px);
		transform:         translateY(-5px)
	}
}

@media (max-width: 800px) {
    .screen-inner {
        width: 100%;
        padding: 10px;
    }
    .navigation i {
        display: inline;
        margin: 0 20px;
    }
}
