#products-amount-list {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
}

#products-amount-list li {
    list-style-type: none;
    margin: 20px;
    width: 230px;
}

.product-amount-selector {
    display: flex;
    flex-wrap: wrap;
}

.product-amount-selector:hover {
    background-color: rgba(0,0,0,0.2);
}
.product-amount-selector-name {
    display: block;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 26px;
}

.product-amount-selector-name:hover {
    text-decoration: none;
    color: #fff;

}

.product-amount-selector-amount {
    flex: 1;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    font-size: 28px;
}

.product-amount-selector-amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.product-amount-selector-button {
    flex: 1;
}

@media (max-width: 800px) {
    #products-amount-list {
        
    }
    #products-amount-list li {
        margin: 10px auto;
        width: 165px;
    }
    .product-amount-selector.well {
        padding: 5px;
    }
    .product-amount-selector-name {
        font-size: 18px;
    }
    #products-amount-list li .btn-lg {
        font-size: 1.0rem;
    }
    #products-amount-list li .btn3d {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .product-amount-selector-amount {
        font-size: 22px;
    }
    .toppings-lists {
        justify-content: center;
    }
}