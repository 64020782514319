#admin .product-toppings-selector-list {
    display: flex;
}

#admin .product-toppings-selector {
    margin: 5px;
}
#admin .toppings-lists {
    display: flex;
}
#admin .toppings-lists>div {
    margin-left: 10px;
    text-align: center;
}
#admin .toppings-list {
    margin: 5px;
    width: 200px;
    flex-wrap: wrap;
    display: flex;
}
#admin .toppings-list .topping {
    flex-basis: 0;
    flex-grow: 1;
    margin: 5px;
}

#admin .toppings-list .topping input {
    display: none;
}

#admin .toppings-list .topping label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
}

#admin .toppings-list .selected label {
}

.order-details-inputs-group input[type=checkbox] {
    margin-left: 5px;
}