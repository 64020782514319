h2 {
    color: #fff;
    text-align: center;
    font-weight: 800;
    text-shadow:
    0px 1px 0px #ccc,
    0px 2px 0px #bbb,
    0px 3px 0px #aaa,
    0px 4px 7px #222;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.instructions {
    color: #fff;
    text-align: center;
    
    margin-bottom: 20px;
}
.mobile-only {
    display: none;
}
@media (max-width: 800px) {
    .mobile-only {
        display: initial;
    }
}

.well {
    padding: 10px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39);
}

#client-details input {
    margin-bottom: 10px;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.2), 0 0 6px rgba(0,0,0,0.2);
}

#thank-you {
    color: #fff;
    text-align: center;
}

#order-summery #order-summery-time {
    color: #FFF;
}

#orders-conformation-list, #orders-conformation-list ul {
    list-style-type: none;
    padding: 0;
}

#orders-conformation-list {
    width: 300px;
}

#orders-conformation-list>li {
    padding: 5px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

#orders-conformation-list>li:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#orders-conformation-list>li>h3{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    margin-bottom: 0;
}

#orders-conformation-list .orders-conformation-toppingsParts-list {
    display: flex;
}

#orders-conformation-list .orders-conformation-toppingsParts-list>li {
    flex: 1;
    margin: 5px;
    padding: 5px;
    color: #fff;
}

@media (max-width: 800px) {
    #orders-conformation-list {
        display: block;
        overflow-y: scroll;
        height: 400px;
        width: 100%;
    }
    #orders-conformation-list::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(255,255,255,0.3);;
    }
    
    #orders-conformation-list::-webkit-scrollbar {
        height: 16px;
        background-color: transparent;
    }
    
    #orders-conformation-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
    }
}