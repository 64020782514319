* {
  font-family: 'Heebo', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  direction: rtl;
}

.product-toppings-selector-list {
    display: flex;
    justify-content: center;
}

.product-toppings-selector {
    margin: 5px;
}
.product-toppings-selector-name {
    text-align: center;
    color: #fff;
    font-size: 24px;
}
.toppings-lists {
    display: flex;
}
.toppings-lists .toppings-lists-name {
    text-align: center;
    color: #fff;
    font-size: 18px;
    display: block;
}
.toppings-list {
    margin: 5px;
    flex-wrap: wrap;
    display: flex;
    max-width: 250px;
}
.toppings-list .topping {
    flex-basis: 0;
    flex-grow: 1;
    margin: 4px 3px;
}

.toppings-list .topping input {
    display: none;
}

.toppings-list .topping label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
}

.toppings-list .topping input:checked + label {
}

@media (max-width: 800px) {
    .product-toppings-selector-list {
        display: block;
        overflow-y: scroll;
        height: 450px;
    }
    .product-toppings-selector-list::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(255,255,255,0.3);;
    }
    
    .product-toppings-selector-list::-webkit-scrollbar {
        height: 16px;
        background-color: transparent;
    }
    
    .product-toppings-selector-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
    }
}

html, body {
    overflow: hidden;
    scroll-behavior: smooth;
}

html, body, #root, #client, #screens-wraper {
    height: 100%;
}

#screens-wraper {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.screen {
    min-height: 100%;
    position: relative;
    display: flex;
}

.screen-inner {
    margin: auto;
}

.navigation {
    background-color: #333;
    position: absolute;
    width: 100%; /* Safari */
    transition: opacity 1s;
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: 800;
}

.navigation:hover {
    text-decoration: none;
    color: #fff;
}
.navigation i {
    display: block;
    
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
.button-back:hover i {
-webkit-animation-name: bounce-back;
        animation-name: bounce-back;
-moz-animation-name: bounce-back;
}
.button-next:hover i {
    -webkit-animation-name: bounce-next;
            animation-name: bounce-next;
    -moz-animation-name: bounce-next;
}

.button-back {
    top: 0;
}
.button-next {
    bottom: 0;
}

@-webkit-keyframes bounce-back {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(10px);
		transform:         translateY(10px)
	}
	60% {
		-webkit-transform: translateY(5px);
		transform:         translateY(5px)
	}
}

@keyframes bounce-back {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(10px);
		transform:         translateY(10px)
	}
	60% {
		-webkit-transform: translateY(5px);
		transform:         translateY(5px)
	}
}

@-webkit-keyframes bounce-next {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-10px);
		transform:         translateY(-10px)
	}
	60% {
		-webkit-transform: translateY(-5px);
		transform:         translateY(-5px)
	}
}

@keyframes bounce-next {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-10px);
		transform:         translateY(-10px)
	}
	60% {
		-webkit-transform: translateY(-5px);
		transform:         translateY(-5px)
	}
}

@media (max-width: 800px) {
    .screen-inner {
        width: 100%;
        padding: 10px;
    }
    .navigation i {
        display: inline;
        margin: 0 20px;
    }
}

h2 {
    color: #fff;
    text-align: center;
    font-weight: 800;
    text-shadow:
    0px 1px 0px #ccc,
    0px 2px 0px #bbb,
    0px 3px 0px #aaa,
    0px 4px 7px #222;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.instructions {
    color: #fff;
    text-align: center;
    
    margin-bottom: 20px;
}
.mobile-only {
    display: none;
}
@media (max-width: 800px) {
    .mobile-only {
        display: inline;
        display: initial;
    }
}

.well {
    padding: 10px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39);
}

#client-details input {
    margin-bottom: 10px;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.2), 0 0 6px rgba(0,0,0,0.2);
}

#thank-you {
    color: #fff;
    text-align: center;
}

#order-summery #order-summery-time {
    color: #FFF;
}

#orders-conformation-list, #orders-conformation-list ul {
    list-style-type: none;
    padding: 0;
}

#orders-conformation-list {
    width: 300px;
}

#orders-conformation-list>li {
    padding: 5px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

#orders-conformation-list>li:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#orders-conformation-list>li>h3{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    margin-bottom: 0;
}

#orders-conformation-list .orders-conformation-toppingsParts-list {
    display: flex;
}

#orders-conformation-list .orders-conformation-toppingsParts-list>li {
    flex: 1 1;
    margin: 5px;
    padding: 5px;
    color: #fff;
}

@media (max-width: 800px) {
    #orders-conformation-list {
        display: block;
        overflow-y: scroll;
        height: 400px;
        width: 100%;
    }
    #orders-conformation-list::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(255,255,255,0.3);;
    }
    
    #orders-conformation-list::-webkit-scrollbar {
        height: 16px;
        background-color: transparent;
    }
    
    #orders-conformation-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
    }
}
.btn3d {
	position: relative;
	top: -6px;
	border: 0;
	transition: all 40ms linear;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 2px;
	margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
	-moz-outline-style: none;
	outline: medium none;
}

.btn3d:active,
.btn3d.active {
	top: 2px;
}

.btn3d.btn-white {
	color: #666666;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #f5f5f5, 0 8px 8px 1px rgba(0, 0, 0, .2);
	background-color: #fff;
}

.btn3d.btn-white:active,
.btn3d.btn-white.active {
	color: #666666;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
	background-color: #fff;
}

.btn3d.btn-default {
	color: #666666;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #BEBEBE, 0 8px 8px 1px rgba(0, 0, 0, .2);
	background-color: #f9f9f9;
}

.btn3d.btn-default:active,
.btn3d.btn-default.active {
	color: #666666;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
	background-color: #f9f9f9;
}

.btn3d.btn-primary {
	box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #4D5BBE, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #4274D7;
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
	box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #4274D7;
}

.btn3d.btn-success {
	box-shadow: 0 0 0 1px #31c300 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #5eb924, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #78d739;
}

.btn3d.btn-success:active,
.btn3d.btn-success.active {
	box-shadow: 0 0 0 1px #30cd00 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #78d739;
}

.btn3d.btn-info {
	box-shadow: 0 0 0 1px #00a5c3 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #348FD2, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #39B3D7;
}

.btn3d.btn-info:active,
.btn3d.btn-info.active {
	box-shadow: 0 0 0 1px #00a5c3 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #39B3D7;
}

.btn3d.btn-warning {
	box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #D79A34, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #FEAF20;
}

.btn3d.btn-warning:active,
.btn3d.btn-warning.active {
	box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #FEAF20;
}

.btn3d.btn-danger {
	box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #AA0000, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #D73814;
}

.btn3d.btn-danger:active,
.btn3d.btn-danger.active {
	box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #D73814;
}

.btn3d.btn-magick {
	color: #fff;
	box-shadow: 0 0 0 1px #9a00cd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #9823d5, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
	background-color: #bb39d7;
}

.btn3d.btn-magick:active,
.btn3d.btn-magick.active {
	box-shadow: 0 0 0 1px #9a00cd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
	background-color: #bb39d7;
}

.btn3d.btn-default-low {
	top: -2px;
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 3px 0 0 #BEBEBE, 0 3px 3px 1px rgba(0, 0, 0, .2);
    background-color: #dddddd;
}

.btn3d.btn-default-low:active,
.btn3d.btn-default-low.active {
	top: 0px;
	color: #666666;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
	background-color: #f9f9f9;
}

input:checked + .btn3d.btn-default-low {
	top: 1px;
	color: #555;
	box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 -1px 0 0 #BEBEBE, 0 -2px 2px 1px rgba(0, 0, 0, .2);
	background-color: #fff;
	font-weight: 800;
}



.btn3d.btn-danger-low {
	top: -2px;
    color: #fff;
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 3px 0 0 #AA0000, 0 3px 3px 1px rgba(0, 0, 0, .2);
    background-color: #D73814;
}

.btn3d.btn-danger-low:active,
.btn3d.btn-danger-low.active {
	top: 0px;
	color: #fff;
	box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
	background-color: #D73814;
}

input:checked + .btn3d.btn-danger-low {
	top: 1px;
	color: #fff;
	box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 -1px 0 0 #AA0000, 0 -2px 2px 1px rgba(0, 0, 0, .2);
	background-color: #D73814;
	font-weight: 800;
}
#products-amount-list {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
}

#products-amount-list li {
    list-style-type: none;
    margin: 20px;
    width: 230px;
}

.product-amount-selector {
    display: flex;
    flex-wrap: wrap;
}

.product-amount-selector:hover {
    background-color: rgba(0,0,0,0.2);
}
.product-amount-selector-name {
    display: block;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 26px;
}

.product-amount-selector-name:hover {
    text-decoration: none;
    color: #fff;

}

.product-amount-selector-amount {
    flex: 1 1;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    font-size: 28px;
}

.product-amount-selector-amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.product-amount-selector-button {
    flex: 1 1;
}

@media (max-width: 800px) {
    #products-amount-list {
        
    }
    #products-amount-list li {
        margin: 10px auto;
        width: 165px;
    }
    .product-amount-selector.well {
        padding: 5px;
    }
    .product-amount-selector-name {
        font-size: 18px;
    }
    #products-amount-list li .btn-lg {
        font-size: 1.0rem;
    }
    #products-amount-list li .btn3d {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .product-amount-selector-amount {
        font-size: 22px;
    }
    .toppings-lists {
        justify-content: center;
    }
}
#time-selector ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#time-selector {
    width: 900px;
}
#time-selector-slider-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: 5px;
}
#time-selector-hours,
#time-selector-slider,
.time-selector-slider-slots {
    display: flex;
}
.time-selector-hour,
.time-selector-slider-hour,
.time-selector-slider-slot {
    flex-grow: 1;
    margin: 5px;
    flex-basis: 0;
}

ul#time-selector-hours {
    margin-bottom: 10px;
}

.time-selector-hour {
    display: block;
    text-align: center;
    cursor: pointer;
}

.time-selector-slider-hour {
    padding: 0;
}

.time-selector-slider-hour-title {
    margin: 5px;
    display: block;
    text-align: center;
    color: #FFF;
    font-size: 18px;
    opacity: 0.5;
}
.time-selector-slider-slot {
    display: block;
    text-align: center;
}
.time-selector-slider-slot-h {
    opacity: 0.5;
}
.time-selector-slider-slot-m {
    font-weight: 800;
}
.time-selector-slider-slot.available {
    /*cursor: pointer;*/
}
.time-selector-slider-slot.not-available {
    opacity: 0.2;
}

.time-selector-slider-slot input {
    display: none;
}

.time-selector-slider-slot label {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.time-selector-slider-slot input:checked + label {
}

#time-selector-slider-container::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: rgba(255,255,255,0.3);;
}

#time-selector-slider-container::-webkit-scrollbar {
	height: 16px;
	background-color: transparent;
}

#time-selector-slider-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #FFF;
}

@media (max-width: 800px) {
    #time-selector {
        width: 360px;
    }
    #time-selector-slider {
        width: 1080px !important;
    }
    .time-selector-slider-slots {
        flex-wrap: wrap
    }

}
#admin .product-toppings-selector-list {
    display: flex;
}

#admin .product-toppings-selector {
    margin: 5px;
}
#admin .toppings-lists {
    display: flex;
}
#admin .toppings-lists>div {
    margin-left: 10px;
    text-align: center;
}
#admin .toppings-list {
    margin: 5px;
    width: 200px;
    flex-wrap: wrap;
    display: flex;
}
#admin .toppings-list .topping {
    flex-basis: 0;
    flex-grow: 1;
    margin: 5px;
}

#admin .toppings-list .topping input {
    display: none;
}

#admin .toppings-list .topping label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
}

#admin .toppings-list .selected label {
}

.order-details-inputs-group input[type=checkbox] {
    margin-left: 5px;
}
.well {
    padding: 10px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39);
}

input[type="text"] {
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.2), 0 0 6px rgba(0,0,0,0.2);
}

#admin {
    height: 100%;
    overflow: auto;
    background-color: #333;
}

#nav {
    padding: 5px 10px 0 10px;
}
#nav::after {
    content: "";
    clear: both;
    display: table;
}
#nav-title {
    float: right;
    color: #fff;
    margin: 0;
}
#nav-buttons {
    float: left;
}

#nav-buttons button i {
    margin-left: 5px;
}

#admin #slots-list {
    list-style: none;
    padding: 0;
}

#admin #slots-list .slot {
    display: flex;
    flex-flow: row wrap;
    margin: 5px;
    border-radius: 5px;
}

#admin #slots-list .slot.odd-hour-slot {
    background-color: rgba(255, 255, 255, 0.05);
}

#admin #slots-list .slot.even-hour-slot {
    background-color: rgba(255, 255, 255, 0.1);
}


#admin #slots-list .slot-time {
    flex-grow: 0;
    color: #fff;
    padding: 10px;
    font-weight: 800;
    opacity: 0.7;
    border-left: #999 dashed 1px;
    display: flex;
    align-items: center;

    display: flex;
    flex-direction: column;
}

#admin #slots-list .slot-time-to {
    opacity: 0.3;
    font-size: 14px;
    font-weight: 400;
}

#admin #slots-list .lock-slot,
#admin #slots-list .add-order {
    flex-grow: 0;
    width: 50px;
    margin: 0 7px;
}

#admin #slots-list .lock-slot.btn3d,
#admin #slots-list .add-order.btn3d {
    position: static;
    margin-top: 10px;
    margin-bottom: 16px;
}

#admin #slots-list .lock-slot.btn3d:active,
#admin #slots-list .add-order.btn3d:active {
    margin-top: 16px;
    margin-bottom: 10px;
}

#admin .subSlots-list-container {
    flex-grow: 1;
}

#admin .subSlots-list {
    list-style: none;
    padding: 0;
    display: flex;
}


#admin .subSlots-list .subSlot {
    flex-grow: 1;
    flex-basis: 0;
    border-left: 3px solid #333;
}

#admin .subSlots-list .subSlot:nth-last-child(2) {
    background-image: linear-gradient(45deg, rgba(0,0,0,0.05) 21.43%, transparent 21.43%, transparent 50%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.05) 71.43%, transparent 71.43%, transparent 100%);
    background-size: 9.90px 9.90px;
}

#admin .subSlots-list .subSlot:last-child {
    flex-grow: 0.5;
    background-image: linear-gradient(45deg, rgba(255, 0, 0, 0.1) 21.43%, transparent 21.43%, transparent 50%, rgba(255,0,0,0.1) 50%, rgba(255,0,0,0.1) 71.43%, transparent 71.43%, transparent 100%);
    background-size: 9.90px 9.90px;
}
#admin .orders-list {
    list-style: none;
    padding: 0;
    display: flex;
}

#admin .orders-list .order {
    text-align: center;
    padding-left: 6px;
}
#admin .orders-list .order:first-child {
    padding-right: 3px;
}


#admin .orders-list .order.unnoted {
    margin-bottom: 3px;
    border-bottom-width: 3px;
    border-bottom-style: solid;

    border-image: linear-gradient(to left, transparent, #ff0000, #ff0000, transparent);
    border-image-slice: 1;
    
    /*border-bottom-color: #ff0000;*/
}

#admin .orders-list .order.unnoted .btn3d {
    margin-bottom: 4px;
}

#admin .orders-list .order a {
    width: 100%;
    padding: 5px;
}

#admin .orders-list .order .order-icon img {
    height: 25px;
}

#admin .orders-list .order .order-clientName {
    font-size: 12px;
    line-height: 18px;
}

#admin .orders-list .order.order-size-4 .order-clientName {
    font-size: 11px;
    line-height: 18px;
}
#admin .orders-list .order.order-size-4 .order-icon img {
    height: 20px;
    margin: 2.5px 0;
}

#admin .order-details-container,
#admin .add-order-container {
    height:0;
    overflow: hidden;
    transition:
        height 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94),
        padding 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    box-sizing: border-box;
}

#admin .order-details-container.show,
#admin .add-order-container.show {
    display: block;
}

#admin .order-details form {
    display: flex;
    margin-right: 10px;
}

#admin .order-details form .order-details-inputs-group label {
    margin-left: 10px;
    color: #fff;
    display: block;
}

#admin .order-details form>label {
    color: #fff;
}

#admin .order-details-inputs-group {
}

#admin .order-details-buttons {
    display: flex;
    flex-direction: column;
}

#admin .order-details-buttons i {
    margin-left: 5px;
}

#charging-window-container,
#change-date-window-container {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0,0,0,0.5);
}

#charging-window-container #charging-window,
#change-date-window-container #change-date-window {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    height: 200px;
    margin-top: -100px;

    background-color: #999;
    box-shadow: 0 0 10px #000;
    border-radius: 4px;
    padding: 40px 10px;

    text-align: center;
}

#charging-window-container #charging-window .form-inline,
#change-date-window-container #change-date-window .form-inline {
    justify-content: center;
}

#charging-window-container #charging-window input,
#charging-window-container #charging-window button,
#change-date-window-container #change-date-window input,
#change-date-window-container #change-date-window button {
    margin-left: 5px;
}

#charging-window-container #charging-window input[name="chargingYear"],
#change-date-window-container #change-date-window input[name="changeDateYear"] {
    width: 70px;
}

#charging-window-container #charging-window input[name="chargingMonth"],
#charging-window-container #charging-window input[name="chargingDay"],
#change-date-window-container #change-date-window input[name="changeDateMonth"],
#change-date-window-container #change-date-window input[name="changeDateDay"] {
    width: 50px;
}
