#time-selector ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#time-selector {
    width: 900px;
}
#time-selector-slider-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: 5px;
}
#time-selector-hours,
#time-selector-slider,
.time-selector-slider-slots {
    display: flex;
}
.time-selector-hour,
.time-selector-slider-hour,
.time-selector-slider-slot {
    flex-grow: 1;
    margin: 5px;
    flex-basis: 0;
}

ul#time-selector-hours {
    margin-bottom: 10px;
}

.time-selector-hour {
    display: block;
    text-align: center;
    cursor: pointer;
}

.time-selector-slider-hour {
    padding: 0;
}

.time-selector-slider-hour-title {
    margin: 5px;
    display: block;
    text-align: center;
    color: #FFF;
    font-size: 18px;
    opacity: 0.5;
}
.time-selector-slider-slot {
    display: block;
    text-align: center;
}
.time-selector-slider-slot-h {
    opacity: 0.5;
}
.time-selector-slider-slot-m {
    font-weight: 800;
}
.time-selector-slider-slot.available {
    /*cursor: pointer;*/
}
.time-selector-slider-slot.not-available {
    opacity: 0.2;
}

.time-selector-slider-slot input {
    display: none;
}

.time-selector-slider-slot label {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.time-selector-slider-slot input:checked + label {
}

#time-selector-slider-container::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: rgba(255,255,255,0.3);;
}

#time-selector-slider-container::-webkit-scrollbar {
	height: 16px;
	background-color: transparent;
}

#time-selector-slider-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #FFF;
}

@media (max-width: 800px) {
    #time-selector {
        width: 360px;
    }
    #time-selector-slider {
        width: 1080px !important;
    }
    .time-selector-slider-slots {
        flex-wrap: wrap
    }

}